import Api from './Api.js'

export default {
  requestPassCode(payload) {
    return Api().post('/v2/forgotpassword', payload)
  },
  sendPassCode(payload) {
    return Api().post('/v2/verifyotp', payload)
  },
  resetPassword(payload) {
    return Api().post('/v2/resetpassword', payload)
  },
}
