<!-- =========================================================================================
    File Name: ForgotPassword.vue
    Description: Forgot Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard 
      Author:  
    Author URL:  
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img src="@/assets/images/pages/forgot-password.png" alt="login" class="mx-auto" />
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
              <!-- ENTER E-MAIL ID -->
              <div class="p-8" v-if="hideShow.requestPassCode">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Reset your password</h4>
                  <p>Please enter your registered email address and we'll send you an OTP to reset your password.</p>
                </div>

                <div class="mb-8">
                  <vs-input
                    v-validate="'required'"
                    type="email"
                    name="Email"
                    label-placeholder="Email"
                    v-model="form.user_name"
                    class="w-full"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Email')"
                  >{{ errors.first('Email') }}</span>
                </div>
                <vs-button type="border" to="/login" class="px-4 w-full md:w-auto">Back To Login</vs-button>
                <vs-button
                  :disabled="submitFlags.requestPassCode"
                  @click="requestPassCode()"
                  class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0"
                >Send OTP</vs-button>
              </div>

              <!-- ENTER OTP -->
              <div class="p-8" v-if="hideShow.sendPassCode">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Recover your password</h4>
                  <p>Please enter OTP</p>
                </div>

                <div class="mb-8">
                  <vs-input
                    v-validate="'required|digits:6'"
                    name="OTP"
                    label-placeholder="OTP"
                    v-model="form.otp"
                    class="w-full"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('OTP')"
                  >{{ errors.first('OTP') }}</span>
                </div>
                <vs-button type="border" to="/login" class="px-4 w-full md:w-auto">Back To Login</vs-button>
                <vs-button
                  :disabled="submitFlags.sendPassCode"
                  @click="sendPassCode()"
                  class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0"
                >Next</vs-button>
                <vs-button
                  :disabled="submitFlags.requestPassCode"
                  @click="reSendOtp()"
                  class="float-right px-4 w-full md:w-auto mt-3 mr-3 mb-8 md:mt-0 md:mb-0"
                >Resend</vs-button>
              </div>

              <!-- ENTER password-->
              <div class="p-8" v-if="hideShow.resetPassword">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Recover your password</h4>
                  <p>Enter Password</p>
                </div>

                <div class="mb-8">
                  <vs-input
                    v-validate="'required'"
                    type="password"
                    name="New Password"
                    label-placeholder="New Password"
                    v-model="form.password"
                    class="w-full"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('New Password')"
                  >{{ errors.first('New Password') }}</span>
                </div>

                <!-- <vs-input
                  type="password"
                  label-placeholder="Confirm Password"
                  v-model="form.password"
                  class="w-full mb-8"
                />-->
                <vs-button type="border" to="/login" class="px-4 w-full md:w-auto">Back To Login</vs-button>
                <vs-button
                  :disabled="submitFlags.resetPassword"
                  @click="resetPassword()"
                  class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0"
                >Update</vs-button>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import passwordRecoveryService from "@/services/passwordRecoveryService.js";

export default {
  data() {
    return {
      form: {
        user_name: "",
        otp: "",
        password: "",
        reset_token: "",
      },
      submitFlags: {
        requestPassCode: false,
        sendPassCode: true,
        resetPassword: false,
      },
      // submitFlags: {
      //   requestPassCode: true,
      //   sendPassCode: true,
      //   resetPassword: true,
      // },
      // form hide show
      hideShow: {
        requestPassCode: true,
        sendPassCode: false,
        resetPassword: false,
      },
    };
  },
  watch: {
    "form.user_name"(newVal, oldVal) {
      String(newVal).length > 0
        ? (this.submitFlags.requestPassCode = false)
        : (this.submitFlags.requestPassCode = true);
    },
    "form.otp"(newVal, oldVal) {
      String(newVal).length == 6
        ? (this.submitFlags.sendPassCode = false)
        : (this.submitFlags.sendPassCode = true);
    },
    "form.password"(newVal) {
      String(newVal).length > 0
        ? (this.submitFlags.resetPassword = false)
        : (this.submitFlags.resetPassword = true);
    },
    //  form:{
    //     handler:(newVal,oldVal)=>{
    //     if(this.submitFlags.requestPassCode){
    //       this.
    //     }
    //   }, deep: true
    //  }
  },
  beforeMount() {},
  methods: {
    hideShowAll: function(show) {
      for (const key of Object.keys(this.hideShow)) {
        show !== key
          ? (this.hideShow[key] = false)
          : show === key
          ? (this.hideShow[show] = true)
          : "";
      }
    },

    requestPassCode: function() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading();
          let payload = {
            user_name: this.form.user_name,
          };
          this.$vs.loading();
          this.submitFlags.requestPassCode = true;
          passwordRecoveryService
            .requestPassCode(payload)
            .then(response => {
              this.$vs.loading.close();

              this.submitFlags.requestPassCode = false;
              const { data } = response;
              if (data.Status == true) {
                this.$vs.notify({
                  text: data.Message,
                  time: 6000,

                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                this.hideShowAll("sendPassCode");
              } else {
                this.$vs.loading.close();
                //email address wrong
                this.$vs.notify({
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch(err => {
              this.$vs.loading.close();
            });
        } else {
        }
      });
    },

    sendPassCode: function() {
      console.log("object");
      this.$validator.validateAll().then(result => {
        console.log("result", result);
        this.$vs.loading();

        let payload = {
          user_name: this.form.user_name,
          otp: this.form.otp,
        };
        this.$vs.loading();
        // console.log("payload", payload);
        // if (result) {
        this.submitFlags.sendPassCode = false;
        passwordRecoveryService
          .sendPassCode(payload)
          .then(response => {
            this.submitFlags.sendPassCode = false;
            const { data } = response;
            if (data.Status == true) {
              this.form.reset_token = data.data.token;
              this.$vs.loading.close();
              this.$vs.notify({
                title: "OTP Success!",
                text: data.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "success",
              });
              this.$vs.loading.close();
              this.hideShowAll("resetPassword");
            } else {
              //email address wrong
              this.$vs.notify({
                title: "OTP Does not match!",
                text: data.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "warning",
              });
            }
          })
          .catch(err => {});
        // } else {
        // }
      });
    },

    reSendOtp: function() {
      this.$vs.loading();

      let payload = {
        user_name: this.form.user_name,
      };
      this.$vs.loading();
      this.submitFlags.requestPassCode = true;
      passwordRecoveryService
        .requestPassCode(payload)
        .then(response => {
          this.submitFlags.requestPassCode = false;
          const { data } = response;
          if (data.Status == true) {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "New OTP send to your Email!",
              time: 5000,
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            this.$vs.loading.close();
          } else {
            //email address wrong
          }
        })
        .catch(err => {});
    },

    resetPassword: function() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading();

          let payload = {
            ...this.form,
          };
          this.$vs.loading();
          this.submitFlags.resetPassword = true;
          passwordRecoveryService
            .resetPassword(payload)
            .then(response => {
              this.submitFlags.resetPassword = false;
              const { data } = response;
              if (data.Status == true) {
                this.$vs.loading.close();
                this.$vs.notify({
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                this.$vs.loading.close();
                this.$router.push({
                  path: "/login",
                });
              } else {
                this.$vs.loading.close();
                //email address wrong
                this.$vs.notify({
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
              }
            })
            .catch(err => {});
        } else {
        }
      });
    },
  },
};
</script>
